import {Controller} from '@hotwired/stimulus'
import {Chart, registerables} from 'chart.js'

Chart.register(...registerables)

export default class extends Controller {
    static values = {
        dataSets: Array,
        labels: Array
    }

    connect() {
        this.chart = new Chart(this.element, {
            type: 'pie',
            data: {
                labels: this.labelsValue,
                datasets: this.dataSetsValue
            }
        })
    }

    disconnect() {
        this.chart.destroy()
    }
}
