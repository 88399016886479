import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['projectSelect']

    connect() {
        this.projectSelectTarget.addEventListener('change', this._load.bind(this))
        if (this.projectSelectTarget.value) this._load()
    }

    async _load() {
        const url = this.projectSelectTarget.querySelector(`option[value="${this.projectSelectTarget.value}"]`).getAttribute('data-url')
        if (!url) return

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`,
                'Accept': 'text/vnd.turbo-stream.html'
            }
        })
        const text = await response.text()
        Turbo.renderStreamMessage(text)
    }
}
