import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['select']

    connect() {
    }

    next() {
        let selectedIndex = this.selectTarget.selectedIndex
        if (selectedIndex === 0) selectedIndex = this.selectTarget.options.length

        this.selectTarget.selectedIndex = selectedIndex - 1
        this._triggerChange()
    }

    prev() {
        let selectedIndex = this.selectTarget.selectedIndex
        if (selectedIndex >= (this.selectTarget.options.length - 1)) selectedIndex = -1

        this.selectTarget.selectedIndex = selectedIndex + 1
        this._triggerChange()
    }

    _triggerChange() {
        this.selectTarget.dispatchEvent(new CustomEvent('change'))
    }
}
