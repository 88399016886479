import {Controller} from '@hotwired/stimulus'

import PickMe from 'pick-me'

export default class extends Controller {
    static values = {
        search: Boolean,
        dropdownAlignRight: Boolean
    }

    connect() {
        this.load()
    }

    load() {
        let options = this.options
        Object.keys(options).forEach(function (key) {
            if (options[key] === '') delete options[key]
        })

        const elementId = this.element.getAttribute('id')
        new PickMe(Object.assign({}, this.defaultOptions, {id: elementId}, options))
    }

    get options() {
        return {
            search: {
                enabled: this.hasSearchValue ? this.searchValue : true,
                debounce: 0,
                input: {
                    classList: ['form-control']
                }
            },
            base: {
                popup: {
                    alignRight: this.dropdownAlignRightValue ? this.dropdownAlignRightValue : false,
                    width: '300px'
                }
            }
        }
    }

    get defaultOptions() {
        return {
            language: {
                locale: document.documentElement.lang
            },
            button: {
                classList: ['form-select'],
                iconHtml: ' '
            },
            list: {
                checkedIconHtml: '<i class="fas fa-fw fa-check"></i>'
            }
        }
    }
}
