import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        const storedTheme = localStorage.getItem('theme')
        const preferredTheme = this.getPreferredTheme(storedTheme)

        this.setTheme(preferredTheme)

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
            if (storedTheme !== 'light' || storedTheme !== 'dark') {
                this.setTheme(preferredTheme)
            }
        })

        this.showActiveTheme(preferredTheme)

        document.querySelectorAll('[data-bs-theme-value]')
            .forEach(toggle => {
                toggle.addEventListener('click', () => {
                    const theme = toggle.getAttribute('data-bs-theme-value')
                    localStorage.setItem('theme', theme)
                    this.setTheme(theme)
                    this.showActiveTheme(theme)
                })
            })
    }

    getPreferredTheme(storedTheme) {
        if (storedTheme) return storedTheme

        return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    }

    setTheme(theme) {
        if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            document.documentElement.setAttribute('data-bs-theme', 'dark')
        } else {
            document.documentElement.setAttribute('data-bs-theme', theme)
        }
    }

    showActiveTheme(theme) {
        const btnToActive = document.querySelector(`[data-bs-theme-value="${theme}"]`)

        document.querySelectorAll('[data-bs-theme-value]').forEach(element => {
            element.classList.remove('active')
        })

        btnToActive.classList.add('active')
    }
}
