import {Controller} from '@hotwired/stimulus'
import {Chart, registerables} from 'chart.js'

Chart.register(...registerables)

export default class extends Controller {
    static values = {
        data: Object
    }

    connect() {
        this.chart = new Chart(this.element, {
            type: 'line',
            data: {
                datasets: [{
                    label: 'Umsatz',
                    data: this.dataValue,
                    fill: true,
                    backgroundColor: 'rgba(39, 125, 8, 0.7)',
                    tension: 0.3,
                    borderWidth: 1,
                    pointStyle: false
                }]
            },
            options: {
                scales: {
                    x: {
                        grid: {display: false}
                    },
                    y: {
                        grid: {display: false},
                        beginAtZero: true,
                        ticks: {
                            callback: value => formatMoney(value)
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label: value => (formatMoney(value.formattedValue))
                        }
                    }
                }
            }
        })
    }

    disconnect() {
        this.chart.destroy()
    }
}

const formatMoney = value => {
    return ` ${value.toString().replaceAll(',', '')}€`
}