import {Controller} from '@hotwired/stimulus'
import * as pdfjs from 'pdfjs-dist'

export default class extends Controller {
    static targets = ['preview', 'projectSelect', 'editor', 'spinner']
    static values = {
        previewUrl: String,
        workerUrl: String
    }

    connect() {
        pdfjs.GlobalWorkerOptions.workerSrc = this.workerUrlValue

        this._generatePdf(this.editorTarget.value)
    }

    previewPdf(e) {
        if (this.timer) clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this._generatePdf(e.detail.data)
            this.timer = null
        }, 200)
    }

    async _generatePdf(data) {
        this.spinnerTarget.classList.remove('d-none')

        const formData = new FormData()
        formData.append('html', data)
        formData.append('project_id', this.projectSelectTarget.value)

        const response = await fetch(this.previewUrlValue, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`,
                'Accept': 'application/pdf'
            }
        })
        const body = await response.blob()

        const reader = new FileReader()
        reader.addEventListener('load', () => (this._renderPdf(reader.result)), false)
        reader.readAsDataURL(body)
    }

    async _renderPdf(data) {
        const loader = pdfjs.getDocument(data)
        const pdf = await loader.promise
        const page = await pdf.getPage(1)
        const scale = 2
        const viewport = page.getViewport({scale: scale})

        if (!this.canvas) {
            this.canvas = document.createElement('canvas')
            this.canvas.width = Math.floor(viewport.width)
            this.canvas.height = Math.floor(viewport.height)
            this.previewTarget.appendChild(this.canvas)
        }
        const canvasContext = this.canvas.getContext('2d')
        const renderContext = {canvasContext, viewport}

        page.render(renderContext)
        this.spinnerTarget.classList.add('d-none')
    }
}
