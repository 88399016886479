import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static values = {
        spinnerTargetSelector: String
    }

    connect() {
        this.requestSubmit()

        this.element.querySelectorAll('input[type="search"]').forEach(input => {
            input.addEventListener('input', this.queueSubmit.bind(this))
        })

        this.element.querySelectorAll('select, input[type="checkbox"], input[type="date"]').forEach(input => {
            input.addEventListener('change', this.submitNow.bind(this))
        })
    }

    queueSubmit() {
        if (this.timer) clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.requestSubmit()
            this.timer = null
        }, 150)
    }

    submitNow() {
        if (this.timer) clearTimeout(this.timer)
        this.requestSubmit()
    }

    requestSubmit() {
        this._showSpinner()
        this.element.requestSubmit()
    }

    _showSpinner() {
        if (!this.spinnerTargetSelectorValue) return

        const target = document.querySelector(this.spinnerTargetSelectorValue)
        if (!target) return

        target.innerHTML = '<div class="text-center p-3"><i class="fas fa-fw fa-spinner fa-spin fa-xl"></i></div>'
    }
}
