import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['checkbox']

    connect() {
        this.element.addEventListener('click', () => (this.checkboxTarget.checked = !this.checkboxTarget.checked))
        this.checkboxTarget.addEventListener('click', e => e.stopPropagation())
    }
}
