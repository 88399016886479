import {Controller} from '@hotwired/stimulus'
import {TutorizeClassicEditor} from 'ckeditor5'
import 'ckeditor5/build/translations/de'

export default class extends Controller {
    connect() {
        TutorizeClassicEditor
            .create(this.element, {
                language: document.documentElement.lang,
                removePlugins: this._removePlugins,
                toolbar: {
                    items: [
                        'heading',
                        'fontSize',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        '|',
                        'alignment',
                        'bulletedList',
                        'numberedList',
                        'outdent',
                        'indent',
                        'insertTable',
                        '|',
                        'link',
                        'removeFormat',
                        'undo',
                        'redo'
                    ]
                }
            })
            .then(editor => {
                this.editor = editor
                this._setupChangeDataEvent()
            })
            .catch(error => {
                console.error(error)
            })
    }

    get _removePlugins() {
        let removePlugins = []

        removePlugins.push('Image')
        removePlugins.push('ImageBlock')
        removePlugins.push('ImageCaption')
        removePlugins.push('ImageInline')
        removePlugins.push('ImageResize')
        removePlugins.push('ImageStyle')
        removePlugins.push('ImageToolbar')
        removePlugins.push('ImageUpload')
        removePlugins.push('MediaEmbed')
        removePlugins.push('TutorizeMediaUpload')

        removePlugins.push('MediaEmbed')
        return removePlugins
    }

    _setupChangeDataEvent() {
        this.editor.model.document.on('change:data', (event) => {
            this.element.dispatchEvent(
                new CustomEvent('input', {
                    bubbles: true,
                    detail: {
                        data: this.editor.getData()
                    }
                })
            )
        })
    }
}
