import {application} from './application'

import NestedForm from 'stimulus-rails-nested-form'
import FilterForm from './filter_form'
import PickMe from './pick_me_controller'
import Ckeditor from './ckeditor_controller'
import PdfPreview from './pdf_previews_controller'
import FetchActivities from './fetch_activities_controller'
import RowCheckbox from './row_checkbox_controller'
import Chart from './chart_controller'
import SelectLoop from './select_loop_controller'
import DarkMode from './dark_mode_controller'
import PieChart from './pie_chart_controller'

application.register('nested-form', NestedForm)
application.register('filter-form', FilterForm)
application.register('pick-me', PickMe)
application.register('ckeditor', Ckeditor)
application.register('pdf-preview', PdfPreview)
application.register('fetch-activities', FetchActivities)
application.register('row-checkbox', RowCheckbox)
application.register('chart', Chart)
application.register('pie-chart', PieChart)
application.register('select-loop', SelectLoop)
application.register('dark-mode', DarkMode)
