import {library, dom} from '@fortawesome/fontawesome-svg-core'
import {
    faHouse,
    faUsers,
    faAddressCard,
    faDiagramProject,
    faTimes,
    faPlus,
    faSearch,
    faCheck,
    faChartLine,
    faChartPie,
    faFileInvoiceDollar,
    faFileInvoice,
    faMoneyCheckDollar,
    faEnvelope,
    faSpinner,
    faListCheck,
    faFilePdf,
    faArrowRight,
    faArrowLeft,
    faArrowRightFromBracket,
    faSun,
    faMoon,
    faCircleHalfStroke
} from '@fortawesome/free-solid-svg-icons'

document.addEventListener('DOMContentLoaded', function (_event) {
    library.add(faHouse, faUsers, faAddressCard, faDiagramProject, faTimes, faPlus, faSearch, faCheck, faChartLine, faChartPie, faArrowRightFromBracket,
        faFileInvoiceDollar, faFileInvoice, faMoneyCheckDollar, faEnvelope, faSpinner, faListCheck, faFilePdf, faArrowRight, faArrowLeft, faSun, faMoon, faCircleHalfStroke)
    dom.watch()
})
